<template>

    <div class='grid'>
        <confirm-dialog></confirm-dialog>
        <div class='col-12 md:col-6 lg:col-12'>

            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>
                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Maaş Onayı Bekleyen Personel Listesi</h5>
                    </div>

                    <div class='col-12 lg:col-4 text-right'>
                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger'/>
                                <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                                    <h3>Maaş Onayı Bekleyen Personel Listesi</h3>
                                    <p class="text-lg">
                                        Proje müdürleri tarafından yapılan maaş değişiklikleri bu sayfada görüntülenir.<br>
                                        <br>
                                        Yönetici tarafından onaylanmadan personel ile ilgili maaş hesaplaması, mesai, avans ve izin tanımlaması yapılamaz.<br>
                                        <br>
                                        Girilen maaş tutarları onay sırasında yönetici tarafından değiştirebilinir.
                                    </p>
                                </Sidebar>
                    </div>
                </div>

                <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true' v-model:filters='filters' filterDisplay='menu' :loading='loadingIndicator'
                           :filters='filters' responsiveLayout='scroll'
                           :globalFilterFields="['project.name','employee.name','employee.surName','employee.identityNumber'] ">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Dropdown :model-value='selectedProject' :options='projectList' option-value='id' option-label='name' @change='projectChange'></Dropdown>
                            
                            <span class='p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width:100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Maaş değişikliği talebi bulunamadı.
                    </template>

                    <template #loading>
                        Maaş değişikliği listesi yükleniyor. Lütfen bekleyin.
                    </template>

                    

                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                            <template #body='{data}'>
                                <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                            </template>
                    </Column>

                      <Column field='employee.name' header='Ad'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                        </template>
                    </Column>

                    <Column header='Proje' style='width:15rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.project.name }}
                        </template>
                    </Column>

                    <Column field='employee.identityNumber' header='T.C. Kimlik' style='width:8rem'>
                    </Column>

                    <Column field='employee.gsm' header='GSM' style='width:7rem'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.employee.gsm"
                               v-if='data.employee.gsm != null && data.employee.gsm != ""'>{{ data.employee.gsm }}</a>
                        </template>
                    </Column>

                    <Column header='Eski Maaş' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatPrice(data.salaryChangeRequest.oldOfficialSalary) }} &#8378;
                        </template>
                    </Column>

                    <Column header='Eski Diğer Maaş' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatPrice(data.salaryChangeRequest.oldUnOfficialSalary) }} &#8378;
                        </template>
                    </Column>

                    <Column header='Eski Toplam Maaş' style='width:8rem'>
                        <template #body='{data}'>
                            <span
                                class='text-red-600 font-bold'>{{ formatPrice(data.salaryChangeRequest.oldOfficialSalary + data.salaryChangeRequest.oldUnOfficialSalary)
                                }} &#8378;</span>
                        </template>
                    </Column>

                    <Column header='Yeni Maaş' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatPrice(data.salaryChangeRequest.officialSalary) }} &#8378;
                        </template>
                    </Column>

                    <Column header='Yeni Diğer Maaş' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatPrice(data.salaryChangeRequest.unOfficialSalary) }} &#8378;
                        </template>
                    </Column>

                    <Column header='Yeni Toplam Maaş' style='width:8rem'>
                        <template #body='{data}'>
                            <span class='text-green-600 font-bold'>{{ formatPrice(data.salaryChangeRequest.unOfficialSalary + data.salaryChangeRequest.officialSalary) }} &#8378;</span>
                        </template>
                    </Column>

                    <Column header='Değişiklik Tarihi' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.salaryChangeRequest.insertDate) }}
                        </template>
                    </Column>

                    <Column header='İşe Giriş Tarihi' style='width:7rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.startDate) }}
                        </template>
                    </Column>

                    <Column field='verified' header='Değişim Onayı' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;'
                               :class="{'text-green-500 pi-check-circle': !data.isSalaryChanged, 'text-pink-500 pi-times-circle': data.isSalaryChanged}"
                               @click='showApproveDialog(data)'></i>
                        </template>
                    </Column>

                    <Column header='Görüntüle' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-search' class='p-button-rounded p-button-primary'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>

    <Dialog v-model:visible='isApproveDialogVisible' :style="{width: '540px'}"
            header='Maaş Değişim Onayı' :modal='true' class='p-fluid'>

        <div class='surface-section'>

            <div class='font-medium text-3xl text-900 mb-3'>
                {{ selectedProjectEmployee.employee.name + ' ' + selectedProjectEmployee.employee.surName }}
            </div>

            <div class='text-500 mb-5'>Bu istek
                {{ selectedProjectEmployee.salaryChangeRequest.managerUser.name + ' ' + selectedProjectEmployee.salaryChangeRequest.managerUser.surName
                }} tarafından, {{ formatDate(selectedProjectEmployee.salaryChangeRequest.managerUser.insertDate) }}
                tarihinde yapılmıştır.
            </div>

            <ul class='list-none p-0 m-0'>

                <li class='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
                    <div class='text-500 w-6 md:w-4 font-medium'>Resmi Maaşı</div>

                    <div class='text-900 w-full md:w-4 md:flex-order-0 flex-order-1'>
                        <div
                            class='align-items-center justify-content-center bg-red-700 border-round text-white pl-2 pr-2 pt-2 pb-2 mr-2'>
                            Eski : {{ selectedProjectEmployee.salaryChangeRequest.oldOfficialSalary }}
                            <span>&#8378;</span>
                        </div>
                    </div>

                    <div class='w-6 md:w-4 flex justify-content-end'>
                        <InputNumber id='officialSalary' autocomplete='off'
                                     v-model='selectedProjectEmployee.salaryChangeRequest.officialSalary' />
                    </div>
                </li>

                <li class='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
                    <div class='text-500 w-6 md:w-4 font-medium'>Diğer Maaşı</div>

                    <div class='text-900 w-full md:w-4 md:flex-order-0 flex-order-1'>
                        <div
                            class='align-items-center justify-content-center bg-red-700 border-round text-white pl-2 pr-2 pt-2 pb-2 mr-2'>
                            Eski : {{ selectedProjectEmployee.salaryChangeRequest.oldUnOfficialSalary }}
                            <span>&#8378;</span>
                        </div>
                    </div>

                    <div class='w-6 md:w-4 flex justify-content-end'>
                        <InputNumber id='unOfficialSalary' autocomplete='off'
                                     v-model='selectedProjectEmployee.salaryChangeRequest.unOfficialSalary' />
                    </div>
                </li>


            </ul>

        </div>

        <template #footer>
            <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='isApproveDialogVisible=false' />
            <Button label='Kaydet' icon='pi pi-check' class='p-button-text' @click='approveSalaryChange' />
        </template>

    </Dialog>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import store from '@/store';
import { showErrorMessage, showSuccessMessage } from '../../components/shared/toast/toastFunction';
import { checkUserRole, getProjects, getUserId } from '../common/commonConstantFunctions';

export default {
    data() {
        return {
            visibleLeft:false,
            selectedProject:-1,
            projectList:[],
            dataList: [],
            filters: null,
            loadingIndicator: true,
            salaryReport: {},
            employeeListReport: {
            totalPersonal: 0,
            totalPrice: 0,
            totalOfficialPrice: 0,
            totalUnOfficialPrice: 0,
            },
            selectedProjectEmployee: {},
            isApproveDialogVisible: false,
        };
    },
    _firmService: null,
    _projectEmployeeService: null,
    created() {
        checkUserRole(this, "Admin");
        this.projectList = getProjects();
        this.projectId = store.getters.getActiveProject.id;
        this._employeeService = new EmployeeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.initFilters();
        this.loadingIndicator = true;
    },

    async mounted() {
        await this.refreshProjectEmployeeList();
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        async refreshProjectEmployeeList() {
            const response = await this._projectEmployeeService.getProjectEmployeesSalaryChangesForAccounting(this.selectedProject);
            if (response.isSuccess) {
                this.dataList = response.data;
            }

            this.loadingIndicator = false;
        },
        async projectChange(event) {
            this.selectedProject = event.value;
            await this.refreshProjectEmployeeList();
        },

        clearFilter() {
            this.initFilters();
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        edit(item) {
            this.$router.push({
                name: 'salaryHistoryProjectEmployee',
                params: { employeeId: item.employeeId, projectEmployeeId: item.id },
            });
        },

        async showApproveDialog(item) {
            this.selectedProjectEmployee = item;
            this.isApproveDialogVisible = true;
        },

        async saveSalaryChange() {
            let updateSalaryItem = {
                officialSalary: this.selectedProjectEmployee.salaryChangeRequest.officialSalary,
                unOfficialSalary: this.selectedProjectEmployee.salaryChangeRequest.unOfficialSalary,
                isSalaryChanged: false,
            };
            let priceUpdateResponse = await this._projectEmployeeService.updateProjectEmployeeSalaryChange(this.selectedProjectEmployee.id, updateSalaryItem);

            if (priceUpdateResponse.isSuccess) {
                let updateSalaryChangeItem = {
                    approveDate: moment().toDate(),
                    approveManagerUserId: getUserId(),
                    officialSalary: this.selectedProjectEmployee.salaryChangeRequest.officialSalary,
                    unOfficialSalary: this.selectedProjectEmployee.salaryChangeRequest.unOfficialSalary,
                    isApproved: true,
                };
                let approveResponse = await this._projectEmployeeService.updateEmployeeSalaryChangeLog(this.selectedProjectEmployee.salaryChangeRequest.id, updateSalaryChangeItem);
                if (approveResponse.isSuccess) {
                    showSuccessMessage(this, 'Maaş Onaylandı');
                    this.isApproveDialogVisible = false;
                    await this.refreshProjectEmployeeList();
                } else {
                    showErrorMessage(this, 'Bir Hata Oluştu');
                }
            } else {
                showErrorMessage(this, 'Maaş Güncellerken');
            }


        },

        async approveSalaryChange() {
            this.$confirm.require({
                message: 'Değişen Maaşı Onaylamak İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.saveSalaryChange();
                },
            });

        },
    },
};
</script>

